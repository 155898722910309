
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import './App.css';
import { SignUp } from './Auth/SignUp';
import { DealerIn } from './Auth/DealerIn';
import { DealerVerification } from './Auth/DealerVerification';
import { SellerNav } from './Dealer/DealerNav';
import { OpenBid } from './Dealer/OpenBid';
import { CarDetails } from './Dealer/CarDetails';
import { WinnerBid } from './Dealer/WinnerBid';
import { WinnerCarBid } from './Dealer/WinnerCarBid';
import ExpiredCars  from './Dealer/ExpiredCars';
import {ExpiredCarDetails} from './Dealer/ExpiredCarDetails';
import {NagitionCars} from './Dealer/NagitionCars';
import {NagitionCarDetails} from './Dealer/NagitionCarDetails';
import {NagitionBids} from './Dealer/NagitionBids';
import {NagitionBidPopUp} from './Dealer/NagitionBidPopUp';
import {FutureCars} from './Dealer/FutureCars';
import {FutureCarDetails} from './Dealer/FutureCarDetails'; 
import {BidHistory} from './Dealer/BidHistory'; 
import {WatchList} from './Dealer/WatchList'; 
import {SimpleCarDetails} from './Dealer/SimpleCarDetails'; 
import {OAuthCallback} from "./Dealer/OAuthCallback"; // Import the OAuth callback handler
function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
  }, []);
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="/dealerin" element={<DealerIn />} />
          <Route path="/verification" element={<DealerVerification />} />
          <Route path="/SellerNav" element={<SellerNav />} />
          <Route path="/open-bid/" element={<OpenBid />} />
          <Route path="/car-details/:Vehicle_Id" element={<CarDetails />} />
          <Route path="/winner-bid" element={<WinnerBid />} />
          <Route path="/expired-cars" element={<ExpiredCars />} />
          <Route path="/winner-car-bid/:Vehicle_Id" element={<WinnerCarBid />} />
          <Route path="/expired-car-details/:Vehicle_Id" element={<ExpiredCarDetails />} />
          <Route path="/nagition-cars" element={<NagitionCars />} />
          <Route path="/nagition-cars-details/:Vehicle_Id" element={<NagitionCarDetails />} />
          <Route path="/nagition-bids/:Vehicle_Id" element={<NagitionBids />} /> 
          <Route path="/nagition-bid-pop" element={<NagitionBidPopUp />} /> 
          <Route path="/future-cars" element={<FutureCars />} /> 
          <Route path="/future-car-details/:Vehicle_Id" element={<FutureCarDetails />} />  
          <Route path="/bid-history/:Vehicle_Id" element={<BidHistory />} />
          <Route path="/watchlist" element={<WatchList />} />
          <Route path="/car-details-expire/:Vehicle_Id" element={<SimpleCarDetails />} />
          <Route path="/oauth/callback" element={<OAuthCallback />} />  {/* OAuth Callback */}
        </Routes>
      </div>
    </Router>

  );
}

export default App;
