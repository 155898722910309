import React, { useEffect, useState } from "react";
import { SellerNav } from "./DealerNav";
import { useLocation } from "react-router-dom";
import api from "../api"; // Import your API instance

export const NagitionBidPopUp = () => {
  const location = useLocation();
  const { bidId, dealerId, sellerId, vehicleId } = location.state || {}; // Destructure the passed state

  // State to hold counter price and comments
  const [counterPrice, setCounterPrice] = useState("");
  const [comments, setComments] = useState("");

  // Log IDs to the console when the component mounts
  useEffect(() => {
    console.log("Negotiation Data:");
    console.log("Bid ID:", bidId);
    console.log("Dealer ID:", dealerId);
    console.log("Seller ID:", sellerId);
    console.log("Vehicle ID:", vehicleId);
  }, [bidId, dealerId, sellerId, vehicleId]);

  // Function to handle form submission
  const handleSubmit = async () => {
    // Assuming bidIds and dealerIds are defined, you may need to adjust them accordingly.
    const bidIds = bidId; // If you have multiple IDs, concatenate them as needed
    const dealerIds = dealerId; // If you have multiple IDs, concatenate them as needed
    const sellerid = sellerId; // Assuming you have the seller ID from state

    const data = {
      BidID: bidIds, // Use the Bid ID from the state
      VehicleID: parseInt(vehicleId), // Convert to a number
      DealerID: dealerIds, // Use the Dealer ID from the state
      SellerID: sellerid, // Use the Seller ID from the state
      CounterPrice: parseFloat(counterPrice.replace(/[$,]/g, '')), // Convert to a number
      Comments: comments, // Comments from the state
      Action: "reply", // Set the action as "new"
      PortalName: "Dealer" // Set the portal name
    };

    try {
      const response = await api.post('/negotiation', data);
      console.log('API Response:', response.data);
      alert('Negotiation submitted successfully');
    } catch (error) {
      console.error('Error submitting negotiation:', error);
      alert('Error submitting negotiation');
    }
  };

  return (
    <section className="car-details">
      <SellerNav />
      <div className="container">
        <div className="nago-mainwrapper">
          <div className="nagotiate-popup">
            <h5>Negotiate Amount</h5>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                value={counterPrice}
                onChange={(e) => setCounterPrice(e.target.value)} // Update state on input change
              />
            </div>
            <h5>Comments</h5>
            <div className="mb-3">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                placeholder="Comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)} // Update state on textarea change
              ></textarea>
            </div>
            <div className="text-center nego-btn mt-4">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
