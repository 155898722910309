import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const OAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const state = urlParams.get("state");

    if (state) {
      const { eventId } = JSON.parse(state);
      console.log("Retrieved Event ID:", eventId);
      navigate(`/future-car-details/${eventId}`); // Redirect to the event page using eventId
    } else {
      console.error("State parameter missing in OAuth response.");
    }
  }, [location, navigate]);

  return <div>Redirecting...</div>;
};
