import React, { useState, useEffect } from 'react';
import { SellerNav } from './DealerNav';
import { useParams } from "react-router-dom";
import api from '../api';

export const BidHistory = () => {
  const { Vehicle_Id } = useParams();
  const [bids, setBids] = useState([]); // All bids
  const [topBids, setTopBids] = useState([]);
  const [newBidAmount, setNewBidAmount] = useState(''); // New bid input field
  const [editBidAmount, setEditBidAmount] = useState({}); // Editable bid values
  const dealerId = sessionStorage.getItem("user_id");
  const [responseMessage, setResponseMessage] = useState("");
  const [deliveryMode, setDeliveryMode] = useState(''); 
  const [highestbid, setHighestBid] = useState("");
  console.log(highestbid)
  const [firmprice, setFirmPrice] = useState("");
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
    const response = await api.get(`/seller/vehicle/${Vehicle_Id}`);
    const vehicleData = response.data;
    console.log(vehicleData)
    setHighestBid(vehicleData.HighestBid);
    setFirmPrice(vehicleData.vehicle.Firm_Price);
    console.log(vehicleData.HighestBid)
  } catch (error) {
    console.error("Error fetching vehicle details:", error);
    }
    };
  // Fetch bid history data from API
  const fetchBidHistory = async () => {
    try {
      const response = await api.get(`/bidhistory/${Vehicle_Id}`);
      const data = response.data;
      setTopBids(data.TopBids);
    } catch (error) {
      console.error('Error fetching bid history:', error);
    }
  };

  useEffect(() => {
    if (Vehicle_Id) {
      fetchBidHistory();
    }
  }, [Vehicle_Id]);




  const handleSaveBid = async (bidId) => {

    // Check if the edited bid is empty
    if (!editBidAmount[bidId]) {
      alert("Bid amount is undefined or empty.");
      return;
    }
  
    // Convert the bid amount string to a number, removing currency symbols and commas
    const editBidPrice = parseFloat(editBidAmount[bidId].replace(/[$,]/g, ''));
  
    // Ensure the edited bid is greater than the highest bid and at least $100 higher
    if (editBidPrice <= highestbid) {
      alert(`Your edited bid must be greater than the current highest bid of $${highestbid}.`);
      return;
    }
    
    // Ensure the edited bid is at least $100 higher than the current highest bid
    if (editBidPrice < highestbid + 100) {
      alert(`Your edited bid must be at least $100 higher than the current highest bid of $${highestbid}.`);
      return;
    }
  
    // Ensure the bid is in increments of $100
    if ((editBidPrice - highestbid) % 100 !== 0) {
      alert("Your bid must be in increments of $100.");
      return;
    }
  
    // Check if delivery mode is selected
    if (!deliveryMode) {
      alert("Please select a delivery mode (Pickup or Drop off).");
      return;
    }
  
    // If all validations pass, attempt to save the bid
    try {
      const response = await api.post('/dealer/dealerbidprice', {
        Bid_Id: bidId,
        Dealer_Id: dealerId,
        Vehicle_Id: Vehicle_Id,
        Bid_Amount: editBidPrice, // Send updated bid amount
        delivery_mode: deliveryMode,
      });
  
      // Show success message
      setResponseMessage('Bid updated successfully!');
      
      // Clear message after 3 seconds
      setTimeout(() => setResponseMessage(''), 3000); 
      
      // Refresh bid history after saving
      fetchBidHistory(); 
    } catch (error) {
      // Handle error saving the bid
      console.error('Error saving bid:', error);
    }
  };

  const handleBidPriceChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d.]/g, "");
    const parts = value.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedValue = `$${parts.join(".")}`;
    setNewBidAmount(formattedValue);
  };

  const formatWithCommas = (value) => {
    // Convert the value to a float, format it with commas, and prepend the dollar sign
    if (isNaN(value) || value === null || value === undefined) {
    return "$0.00";
    }
    return `$${parseFloat(value).toLocaleString()}`;
    };
    // Handle adding a new bid
    const handleAddNewBid = async () => {
      // Ensure the bid amount is not empty
      if (!newBidAmount || newBidAmount.trim() === "") {
        alert("Bid amount cannot be empty.");
        return;
      }
      
      const numericBidPrice = parseFloat(newBidAmount.replace(/[$,]/g, ''));
      
       // Ensure the bid increment is at least 100
       if (numericBidPrice < highestbid + 100) {
        alert(`Your bid must be at least $100 higher than the highest bid.`);
        return;
      }

      // Check if the new bid is lower or equal to the highest bid
      if (numericBidPrice <= highestbid) {
        alert(`Your bid must be greater than the current highest bid of ${highestbid}`);
        return;
      }
    
      // Check if the bid is lower than the firm price
      if (numericBidPrice < firmprice) {
        alert(`Bid price must be greater than ${formatWithCommas(firmprice)}`);
        return;
      }
      if (!deliveryMode) {
        alert("Please select a delivery mode (Pickup or Drop off).");
        return;
      }
      try {
        const response = await api.post("/dealer/dealerbidprice", {
          Bid_Amount: numericBidPrice,
          Dealer_Id: dealerId,
          Vehicle_Id: Vehicle_Id,
          delivery_mode: deliveryMode,
        });
        setResponseMessage(response.data.message || "Bid price saved successfully");
        setTimeout(() => setResponseMessage(''), 3000); // Clear message after 3 seconds
        fetchBidHistory(); // Refresh bid history after saving
      } catch (error) {
        console.error("Error saving bid price:", error);
      }
    };

  // Find if there is a bid by the current dealer
  const yourNegotiation = topBids.find(
    (negotiation) => negotiation.Dealer_Id === Number(dealerId)
  );

  return (
    <section className="car-details">
      <SellerNav />
      <div className="container">
        {responseMessage && (
          <div className="alert alert-success alert-dismissible fade show" role="alert">
            <p className="text-success">{responseMessage}</p>
            <button type="button" className="close" onClick={() => setResponseMessage("")} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        <div className="bidding-historyinfo mt-5">
          <h3 className="main-heading text-center">Bid History</h3>
          <div className="bid-historyinner mt-4">
            <div className="table-panel">
              <table className="table table-bordered mid-table">
                <thead>
                  <tr>
                    <th scope="col">Bid Amount</th>
                    <th scope="col">Bid DATE-TIME</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {topBids.length > 0 ? (
                    topBids.map((bid) => (
                      <tr key={bid.Bid_Id}>
                        <td>
                          {bid.Dealer_Id === Number(dealerId) ? (
                            <input
                              type="text"
                              value={editBidAmount[bid.Bid_Id] || bid.Bid_Amount}
                              onChange={(e) =>
                                setEditBidAmount({
                                  ...editBidAmount,
                                  [bid.Bid_Id]: e.target.value
                                })
                              }
                            />
                          ) : (
                            `$${parseFloat(bid.Bid_Amount).toLocaleString()}`
                          )}
                        </td>
                        <td>{bid.Bid_Date}</td> {/* EDT Timezone */}
                        <td>
                          {bid.Dealer_Id === Number(dealerId) ? (
                            <button className="btn btn-primary" onClick={() => handleSaveBid(bid.Bid_Id)}>
                              Save
                            </button>
                          ) : (
                            <span>Cannot edit</span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No Top Bids Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div class="row m-0">
                  {!yourNegotiation ? (
                    // If there's no negotiation, show the bid input and the delivery mode side by side
                    <>
                      <div class="col-md-6">
                        <div className="add-new-bid mt-5">
                          <h4>Add Your Bid</h4>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              value={newBidAmount}
                              onChange={handleBidPriceChange}
                              placeholder="Enter your bid amount"
                            />
                          </div>
                          <button className="btn btn-primary" onClick={handleAddNewBid}>
                            Save Bid
                          </button>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div className="form-group mt-5">
                          <h4>Delivery Mode</h4>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="pickup"
                              name="deliveryMode"
                              value="Pickup"
                              checked={deliveryMode === 'Pickup'}
                              onChange={() => setDeliveryMode('Pickup')}
                            />
                            <label className="form-check-label" htmlFor="pickup">Pickup</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="dropoff"
                              name="deliveryMode"
                              value="Drop off"
                              checked={deliveryMode === 'Drop off'}
                              onChange={() => setDeliveryMode('Drop off')}
                            />
                            <label className="form-check-label" htmlFor="dropoff">Drop off</label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    // If negotiation exists, make the delivery mode section full-width
                    <div class="col-md-12">
                      <div className="form-group mt-5">
                        <h4>Delivery Mode</h4>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="pickup"
                            name="deliveryMode"
                            value="Pickup"
                            checked={deliveryMode === 'Pickup'}
                            onChange={() => setDeliveryMode('Pickup')}
                          />
                          <label className="form-check-label" htmlFor="pickup">Pickup</label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="dropoff"
                            name="deliveryMode"
                            value="Drop off"
                            checked={deliveryMode === 'Drop off'}
                            onChange={() => setDeliveryMode('Drop off')}
                          />
                          <label className="form-check-label" htmlFor="dropoff">Drop off</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              {/* Add New Bid Section - Only show if no existing bid by the dealer */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
