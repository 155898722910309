import React, { useState, useEffect } from "react";
import { SellerNav } from "./DealerNav";
import api from "../api";
import { useNavigate } from "react-router-dom";

export const WatchList = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const storedDealerId = sessionStorage.getItem("user_id");
  const [searchQuery, setSearchQuery] = useState("");
  const [minutesMap, setMinutesMap] = useState({});
  const [vehicleStatus, setVehicleStatus] = useState(null); // Track vehicle status
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (!storedDealerId) {
          navigate("/sellerverification");
          return; // Stop further execution
        }

        const response = await api.get(
          `/vehiclelisting/${storedDealerId}/Dealer/WatchList`
        );
        const { message, Vehicle } = response.data;

        // Check if message is true and Vehicle is an array
        if (message && Array.isArray(Vehicle)) {
          setPosts(Vehicle);
          setLoading(false);
          Vehicle.forEach((vehicle) => {
            fetchAuctionTimeout(vehicle.Vehicle_Id);
          });
        } else {
          throw new Error("Invalid data format: Vehicle array not found");
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchPosts();
  }, [navigate, storedDealerId]);

  const fetchSerchdata = async () => {
    try {
      const response = await api.post(
        `/vehiclesearch/${storedDealerId}/Dealer/NewCarsToBids`,
        {
          search: searchQuery,
        }
      );

      // Extract the array of vehicles from the "Vehicle" property
      const vehicles = response.data.Vehicle;

      // Set the state with the array of vehicles
      setPosts(vehicles);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    fetchSerchdata();
  };
  useEffect(() => {
    fetchAuctionTimeout();
  }, []);
  const fetchAuctionTimeout = async (vehicleId) => {
    try {
      const response = await api.get(`/timeoutdetails/${vehicleId}`);
  
      setMinutesMap((prevState) => ({
        ...prevState,
        [vehicleId]: response.data.counterminutes, // Store minutes for this vehicle
      }));
    } catch (error) {
      console.error(`Error fetching auction timeout for Vehicle ${vehicleId}:`, error);
    }
  };
    // Add Car to watch list get
    const checkVehicleStatus = async (vehicleId) => {
        try {
          const response = await api.get(`/checkvehiclestatus/${vehicleId}`);
          const data = response.data.status;
          console.log(response.data)
          console.log("Vehicle status:", data);
      
          // Navigate based on the returned status
          if (data === "in-between") {
            console.log("Navigating to car details...");
            navigate(`/car-details/${vehicleId}`);
          } else if (data === "future") {
            console.log("Navigating to future car details...");
            navigate(`/future-car-details/${vehicleId}`);
          } else if (data === "past") {
            console.log("Navigating to past car details...");
            navigate(`/car-details-expire/${vehicleId}`);
          }
        } catch (error) {
          console.error("Error checking vehicle status:", error);
        }
      };
  return (
    <section className="car-details">
      <SellerNav />
      <div className="container">
        <div className="view-post-panel mid-table mt-4">
          <h3 className="main-heading py-3">Cars Open For Biding</h3>
          <div className="car-bid-gallary">
            <form onSubmit={(e) => e.preventDefault()} className="input-group">
              <button type="submit">
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
              <input
                type="search"
                id="form1"
                className="form-control"
                placeholder="Search Cars"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </form>
            <div className="carbid-gallery-panel py-4">
                <div className="row">
                  {loading ? (
                    <p>Loading...</p> // Render loading state while fetching data
                  ) : posts.length === 0 ? (
                    <div className="col-md-12">
                      <p className="text-center text-danger">No car for Bid</p>
                    </div>
                  ) : (
                    posts.map((post) => (
                      <div key={post.Vehicle_Id} className="col-lg-4 col-12">
                           <div
                        className="carbid-image-panel"
                        onClick={() => checkVehicleStatus(post.Vehicle_Id)}
                        style={{ cursor: "pointer" }}
                      >
                            {post.Exterior_Image && (
                              <img
                                src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image}`}
                                alt="Car"
                              />
                            )}
                            {post.Exterior_Image2 && !post.Exterior_Image && (
                              <img
                                src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image2}`}
                                alt="Car"
                              />
                            )}
                            {post.Exterior_Image3 &&
                              !post.Exterior_Image2 &&
                              !post.Exterior_Image && (
                                <img
                                  src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image3}`}
                                  alt="Car"
                                />
                              )}
                            {post.Exterior_Image4 &&
                              !post.Exterior_Image3 &&
                              !post.Exterior_Image2 &&
                              !post.Exterior_Image && (
                                <img
                                  src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image4}`}
                                  alt="Car"
                                />
                              )}
                            <div className="bidpanel-innercont">
                              <h6 className="pt-2">
                                {post.Year} {post.Make} {post.Model}{" "}
                              </h6>
                              <span>
                                <small>Vin #{post.VIN}</small>
                              </span>
                            </div>
                          </div>
                      </div>
                    ))
                  )}
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
