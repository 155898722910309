import React, { useEffect, useState } from "react";
import { SellerNav } from "./DealerNav";
import { useParams } from "react-router-dom"; // import useParams
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import api from "../api";
import { gapi } from 'gapi-script';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce'; // Import debounce function
dayjs.extend(utc);
dayjs.extend(timezone);
export const FutureCarDetails = () => {
const { Vehicle_Id } = useParams(); // Get the ID from the URL slug
const dealerId = sessionStorage.getItem("user_id");
console.log("dealer",dealerId)
const [appreport, setAppeport] = useState("");
const [responseMessage, setResponseMessage] = useState("");
const [bidPrice, setBidPrice] = useState("");
const [loading, setLoading] = useState(false);
const [vehicleData, setVehicleData] = useState(null);
const [sellerid, setSeller] = useState("");
const [vechicleid, setVehicleId] = useState("");
const [highestbid, setHighestBid] = useState("");
const [location, setLocation] = useState("");
const [vin, setVin] = useState("");
const [year, setYear] = useState("");
const [make, setMake] = useState("");
const [model, setModel] = useState("");
const [trim, setTrim] = useState("");
const [mileage, setMileage] = useState("");
const [color, setColor] = useState("");
const [keys, setkeys] = useState("");
const [settire, setSetTire] = useState("");
const [windowtint, setWindowTint] = useState("");
const [aftermarketexhaust, setAftermarketExhaust] = useState("");
const [aftermarkrims, setAftermarkRims] = useState("");
const [roofrack, setRoofRack] = useState("");
const [remotestarter, setRemoteStarter] = useState("");
const [aftermarkstereo, setAftermarkStereo] = useState("");
const [aftermarketspoiler, setAftermarketSpoiler] = useState("");
const [minordamage, setMinorDamage] = useState("");
const [interiorimage, setInteriorImage] = useState("");
const [interiorimagetwo, setInteriorImageTwo] = useState("");
const [interiorimagethree, setInteriorImageThree] = useState("");
const [interiorimagefour, setInteriorImageFour] = useState("");
const [exteriorimage, setExteriorImage] = useState("");
const [exteriorimagetwo, setExteriorImageTwo] = useState("");
const [exteriorimagethree, setExteriorImageThree] = useState("");
const [exteriorimagefour, setExteriorImageFour] = useState("");
const [dashboardimage, setDashboardImage] = useState("");
const [dashboardimagetwo, setDashboardImageTwo] = useState("");
const [dashboardimagethree, setDashboardImageThree] = useState("");
const [dashboardimagefour, setDashboardImageFour] = useState("");
const [rimsimage, setRimsImage] = useState("");
const [rimsimagetwo, setRimsImageTwo] = useState("");
const [rimsimagethree, setRimsImageThree] = useState("");
const [rimsimagefour, setRimsImageFour] = useState("");
const [fadingpaints, setFadingPaints] = useState("");
const [rust, setRust] = useState("");
const [haildamage, setHailDamage] = useState("");
const [extmintcondition, setExtMintCondition] = useState("");
const [dents, setDents] = useState("");
const [ripsOrTears, setRipsOrTears] = useState(false);
const [visibleStain, setVisibleStain] = useState(false);
const [strongSmell, setStrongSmell] = useState(false);
const [damagedSystems, setDamagedSystems] = useState(false);
const [IntmintCondition, setIntMintCondition] = useState(false);
const [smokeInVehicle, setSmokeInVehicle] = useState(false);
const [hasOriginalRims, setHasOriginalRims] = useState(null);
const [tireReplacement, setTireReplacement] = useState(null);
const [vehicleDrivable, setVehicleDrivable] = useState(null);
const [crackOnWindshield, setCrackOnWindshield] = useState(null);
const [extendedWarranty, setExtendedWarranty] = useState(null);
const [tradeInInterest, setTradeInInterest] = useState(null);
const [hasWinterTires, setHasWinterTires] = useState(null);
const [sellTiming, setSellTiming] = useState("");
const [DonotNeedCar, setDonotNeedCar] = useState("");
const [MechElectIssues, setMechElectIssues] = useState("");
const [DownSize, setDownSize] = useState("");
const [BuyAnotherCar, setBuyAnotherCar] = useState("");
const [carCondition, setCarCondition] = useState("");
const [accidentclaims, setAccidentClaims] = useState("");
const [howmuchclaims, setHowMuchClaims] = useState("");
const [carrims, setCarRims] = useState("");
const [stockrim, setStockRim] = useState("");
const [issuevichle, setIssueVichle] = useState("");
const [issuevichledecs, setIssueVichleDecs] = useState("");
const [carmodify, setCarMdify] = useState("");
const [carmodifydecs, setCarMdifyDecs] = useState("");
const [financed, setFinanced] = useState("");
const [financeddesc, setFinancedDesc] = useState("");
const [getbidprice, setGetBidPrice] = useState("");
const [vechiclevideo, setVechicleVideo] = useState("");
const [firmprice, setFirmPrice] = useState("");
const [sellertransmission, setSellerTransmission] = useState('');
const initialBidPrice = getbidprice ? `$${getbidprice}` : "";
const [targetTime, setTargetTime] = useState(null);
const [countdown, setCountdown] = useState('');
const [listedDate, setListedDate] = useState('');
const [hours, sethours] = useState('');
const [minutes, setminutes] = useState('');
const [seconds, setseconds] = useState('');
const [isTimeUp, setIsTimeUp] = useState(false);
const [vechiclenotes, setVechicleNotes] = useState("");
const [vehicledrivabledesc, setVehicleDrivableDes] = useState('');
const [isLoading, setIsLoading] = useState(true);
const [mins, setminutesvar] = useState('');
const [ownership, setOwnerShip] = useState('');
const [drivingl, setDrivingL] = useState('');
const [minutesMap, setMinutesMap] = useState();
const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0 });
const [topBids, setTopBids] = useState([]);
const [currentBid, setCurrentBid] = useState(null);
const [previousBid, setPreviousBid] = useState(null);
const [shownow, setShowNow] = useState(false);
const [newBidAmount, setNewBidAmount] = useState(''); // New bid input field
const [agentDetails, setAgentDetails] = useState(null); // Store agent details
const [deliveryModeShow, setDeliveryModeShow] = useState(false);
const [deliveryMode, setDeliveryMode] = useState(''); 
const [successMessage, setSuccessMessage] = useState("");
const [selectedFeatures, setSelectedFeatures] = useState([]);
const [auctionOverData, setAuctionOverData] = useState('');
const [province, setProvince] = useState('');
const [error, setError] = useState(null);
const [isModalOpen, setIsModalOpen] = useState(false);
const [modalContent, setModalContent] = useState({ type: "", src: "" });
const [isInWatchlist, setIsInWatchlist] = useState(false);
const [saledate, setDropDate] = useState('');
const [live, setlive] = useState('');
const navigate = useNavigate();
const [startBid, setStartBid] = useState("");
const [maxBid, setMaxBid] = useState("");
const [formattedStartBid, setFormattedStartBid] = useState("");
const [formattedMaxBid, setFormattedMaxBid] = useState("");
const [AutoSuccessfullMesage, setAutoSuccessfullMesage] = useState("");
const [imageUrl, setImageUrl] = useState("");
const [message, setMessage] = useState("");
const [evaluationreport, setevaluationreport] = useState("");
const [startBidMessage, setStartBidMessage] = useState("");
const [maxBidMessage, setMaxBidMessage] = useState("");

const formatNumber = (num) => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const handleBidChange = (e, bidType) => {
  const value = e.target.value.replace(/,/g, ""); // Remove commas before saving
  if (bidType === "start") {
      setStartBid(value);
      setFormattedStartBid(formatNumber(value));
  } else if (bidType === "max") {
      setMaxBid(value);
      setFormattedMaxBid(formatNumber(value));
  }
};
useEffect(() => {
  if (startBid) {
    const checkStartBid = async () => {
      try {
        const response = await api.get(`/dealer/checkminbid/${Vehicle_Id}/${dealerId}/${startBid}`);
        console.log("Start Bid Response:", response.data); // Debugging log
        setStartBidMessage(response.data.bidmessage);
      } catch (error) {
        console.error("Error checking start bid:", error);
      }
    };
    checkStartBid();
  }
}, [startBid, Vehicle_Id, dealerId]);

// Check Max Bid when it changes
useEffect(() => {
  if (maxBid) {
    const checkMaxBid = async () => {
      try {
        const response = await api.get(`/dealer/checkmaxbid/${Vehicle_Id}/${dealerId}/${maxBid}`);
        console.log("Max Bid Response:", response.data); // Debugging log
        setMaxBidMessage(response.data.bidmessage);
      } catch (error) {
        console.error("Error checking max bid:", error);
      }
    };
    checkMaxBid();
  }
}, [maxBid, Vehicle_Id, dealerId]);

const isButtonDisabled = startBidMessage || maxBidMessage;
const CLIENT_ID = "412726820929-74ofbuuh9kh52o4v7njjn3biv7oo0ue5.apps.googleusercontent.com";
const API_KEY = "AIzaSyCRz5kOvI1pwXy1UO8B0XxDsYCPXpHZv2w";
const SCOPES = "https://www.googleapis.com/auth/calendar.events";
// Function to open the modal
const [imageIndex, setImageIndex] = useState(0); // Track the current image index


const imageList = [
  interiorimage && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`,
  interiorimagetwo && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`,
  interiorimagethree && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`,
  interiorimagefour && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`,
  exteriorimage && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`,
  exteriorimagetwo && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`,
  exteriorimagethree && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`,
  exteriorimagefour && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`,
  dashboardimage && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`,
  dashboardimagetwo && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`,
  dashboardimagethree && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`,
  dashboardimagefour && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`,
  rimsimage && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`,
  rimsimagetwo && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`,
  rimsimagethree && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagethree}`,
  rimsimagefour && `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`,
].filter(Boolean); // This filters out any falsy values like null or undefined

const openModal = (type, src) => {
  setModalContent({ type, src });
  setImageIndex(imageList.indexOf(src)); // Set the image index when the modal is opened
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};
useEffect(() => {
  // Function to handle keyboard navigation
  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      navigateImage("prev");
    } else if (e.key === "ArrowRight") {
      navigateImage("next");
    }
  };

  // Add event listener for keydown when modal is open
  if (isModalOpen) {
    document.addEventListener("keydown", handleKeyDown);
  }

  // Clean up the event listener when modal is closed
  return () => {
    document.removeEventListener("keydown", handleKeyDown);
  };
}, [isModalOpen, imageIndex]); 
const navigateImage = (direction) => {
  if (direction === "prev") {
    setImageIndex((prevIndex) => (prevIndex === 0 ? imageList.length - 1 : prevIndex - 1));
  } else if (direction === "next") {
    setImageIndex((prevIndex) => (prevIndex === imageList.length - 1 ? 0 : prevIndex + 1));
  }
  setModalContent({ ...modalContent, src: imageList[imageIndex] });
};
// Set target time after fetching minutes from API
 // Fetch bid history data from API


 const fetchPriousBid = debounce(async () => {
  try {
    const response = await api.get(`/dealer/currentpreviousbid/${Vehicle_Id}`);
    const data = response.data;
    setCurrentBid(data.currentBid?.Bid_Amount);
    setPreviousBid(data.previousBid?.Bid_Amount);
  } catch (error) {
    console.error('Error fetching bid history:', error);
  }
}, 1000);

useEffect(() => {
  if (Vehicle_Id) {
    fetchPriousBid();
  
    const interval = setInterval(fetchPriousBid, 5000); // Fetches every 5 seconds
    return () => clearInterval(interval); // Clean up on unmount
  }
}, [Vehicle_Id]);

useEffect(() => {
   if (mins) {
     const target = dayjs().add(mins, 'minute'); // Use mins from API response
     setTargetTime(target);
     console.log('Target Time:', target);
   }
 }, [mins]); // Triggered when mins is fetched // Triggered when mins is fetched

 useEffect(() => {
   let timer;
   if (timeLeft > 0) {
     timer = setInterval(() => {
       setTimeLeft((prevTime) => prevTime - 1000); // Decrease by 1 second (1000 milliseconds)
     }, 1000);
   }

   return () => clearInterval(timer); // Cleanup interval on unmount
 }, [timeLeft]);
 useEffect(() => {
  fetchAuctionTimeout();
}, []);


useEffect(() => {
fetchData();
BidFetchData();
fetchAgentData();
fetchShowDeliveryMode();
}, []);
useEffect(() => {
if (
Vehicle_Id &&
(interiorimage ||
interiorimagetwo ||
interiorimagethree ||
interiorimagefour ||
exteriorimage ||
dashboardimage ||
dashboardimagetwo ||
dashboardimagethree ||
dashboardimagefour ||
rimsimage ||
rimsimagetwo ||
rimsimagethree ||
rimsimagefour)
) {
initializeFlexSlider();
}
}, [
Vehicle_Id,
interiorimage,
interiorimagetwo,
interiorimagethree,
interiorimagefour,
exteriorimage,
dashboardimage,
rimsimage,
]);
const BidFetchData = async () => {
const dealerId = sessionStorage.getItem("user_id");
try {
const response = await api.get(
`/dealer/mybidprice/${Vehicle_Id}/${dealerId}`
);
setGetBidPrice(response.data.MyBidPrice);
} catch (error) {
console.error("Error fetching Bid details:", error);
}
};
const fetchData = async () => {
try {
const response = await api.get(`/seller/vehicle/${Vehicle_Id}`);
const vehicleData = response.data;
console.log(vehicleData)
// Log the entire API response
setSeller(vehicleData.vehicle.Seller_Id);
setListedDate(vehicleData.vehicle.Listed_Date || "");
const listedDate = vehicleData.vehicle.Listed_Date;
setFirmPrice(vehicleData.vehicle.Reserve_Price);
const dbDate = vehicleData.vehicle.Live_Auction_DateTime;
setlive(vehicleData.vehicle.Live_Auction_DateTime || "");
const formattedDate = new Date(dbDate).toISOString();
setDropDate(formattedDate); // Update saledate with formatted d
setLocation(vehicleData.vehicle.Location || "");
setevaluationreport(vehicleData.vehicle.evaluation_report || "");
setHighestBid(vehicleData.HighestBid);
setVin(vehicleData.vehicle.VIN || "");
setYear(vehicleData.vehicle.Year || "");
setMake(vehicleData.vehicle.Make || "");
setModel(vehicleData.vehicle.Model || "");
setTrim(vehicleData.vehicle.Trim || "");
setMileage(vehicleData.vehicle.Mileage || "");
setColor(vehicleData.vehicle.Color || "");
setkeys(vehicleData.vehicle.Car_Keys || "");
setSetTire(vehicleData.vehicle["2_Sets_Of_Tire"] || "");
setWindowTint(vehicleData.vehicle["Win_Tint"] || "");
setAftermarketExhaust(vehicleData.vehicle["Aftermark_Exhaust"] || "");
setAftermarkRims(vehicleData.vehicle["Aftermark_Rims"] || "");
setRoofRack(vehicleData.vehicle["Roof_Rack"] || "");
setRemoteStarter(vehicleData.vehicle["Remote_Start"] || "");
setRemoteStarter(vehicleData.vehicle["Remote_Start"] || "");
setAftermarkStereo(vehicleData.vehicle["Aftermark_Stereo"] || "");
setAftermarketSpoiler(vehicleData.vehicle["Aftermark_Spoiler"] || "");
setMinorDamage(vehicleData.vehicle["Minor_Damage"] || "");
setInteriorImage(vehicleData.vehicle["Interior_Image"] || "");
setInteriorImageTwo(vehicleData.vehicle["Interior_Image2"] || "");
setInteriorImageThree(vehicleData.vehicle["Interior_Image3"] || "");
setInteriorImageFour(vehicleData.vehicle["Interior_Image4"] || "");
setExteriorImage(vehicleData.vehicle["Exterior_Image"] || "");
setExteriorImageTwo(vehicleData.vehicle["Exterior_Image2"] || "");
setExteriorImageThree(vehicleData.vehicle["Exterior_Image3"] || "");
setExteriorImageFour(vehicleData.vehicle["Exterior_Image4"] || "");
setDashboardImage(vehicleData.vehicle["Dashboard_Image"] || "");
setDashboardImageTwo(vehicleData.vehicle["Dashboard_Image2"] || "");
setDashboardImageThree(vehicleData.vehicle["Dashboard_Image3"] || "");
setDashboardImageFour(vehicleData.vehicle["Dashboard_Image4"] || "");
setRimsImage(vehicleData.vehicle["Rims_Image"] || "");
setRimsImageTwo(vehicleData.vehicle["Rims_Image2"] || "");
setRimsImageThree(vehicleData.vehicle["Rims_Image3"] || "");
setRimsImageFour(vehicleData.vehicle["Rims_Image4"] || "");
setFadingPaints(vehicleData.vehicle["Fading_Paints"] || "");
setRust(vehicleData.vehicle["Rust"] || "");
setHailDamage(vehicleData.vehicle["Hail_Damage"] || "");
setExtMintCondition(vehicleData.vehicle["Ext_Mint_Condition"] || "");
setDents(vehicleData.vehicle["Dents"] || "");
setRipsOrTears(vehicleData.vehicle["Seats_Rips_Tears"] || "");
setVisibleStain(vehicleData.vehicle["Seats_Visible_Stain"] || "");
setStrongSmell(vehicleData.vehicle["Strong_Smell"] || "");
setDamagedSystems(vehicleData.vehicle["Nav_Entmt_Ctrl_Dmg_Sys"] || "");
setIntMintCondition(vehicleData.vehicle["Int_Mint_Condition"] || "");
setSmokeInVehicle(vehicleData.vehicle["Smoke_In_Vehicle"] || "No");
setHasOriginalRims(vehicleData.vehicle["Original_Factory_Rims"] || "No");
setTireReplacement(vehicleData.vehicle["Tires_Repld_12_Months"] || "No");
setVehicleDrivable(vehicleData.vehicle["Vehicle_Drivable"] || "No");
setCrackOnWindshield(vehicleData.vehicle["Windshield_Crack"] || "No");
setExtendedWarranty(vehicleData.vehicle["Extended_Warranty"] || "No");
setTradeInInterest(vehicleData.vehicle["TradeIn_Interested"] || "No");
setHasWinterTires(vehicleData.vehicle["Winter_Tires"] || "No");
setVehicleDrivableDes(vehicleData.vehicle['vehicle_drivable_not_explanation'])
setSellTiming(vehicleData.vehicle["How_Soon_Ready_Sell"]);
setDonotNeedCar(vehicleData.vehicle["Donot_Need_Car"] || "");
setMechElectIssues(vehicleData.vehicle["Mech_Elect_Issues"] || "");
setDownSize(vehicleData.vehicle["Downsize"] || "");
setBuyAnotherCar(vehicleData.vehicle["Buy_Another_Car"] || "");
setCarCondition(vehicleData.vehicle["Car_Condition"]);
setAccidentClaims(vehicleData.vehicle["Accident_Claims"] || "No");
setHowMuchClaims(vehicleData.vehicle["How_Much_Claims"] || "");
setCarRims(vehicleData.vehicle["Car_Rims"]);
setStockRim(vehicleData.vehicle["Stock_Rims"] || "No");
setIssueVichle(vehicleData.vehicle["Any_Vehicle_Issues"] || "No");
setIssueVichleDecs(vehicleData.vehicle["Vehicle_Issues_desc"] || "");
setCarMdify(vehicleData.vehicle["Car_Modification"] || "No");
setCarMdifyDecs(vehicleData.vehicle["Modification_desc"] || "");
setFinanced(vehicleData.vehicle["Leased_Financed"] || "No");
setFinancedDesc(vehicleData.vehicle["Financed_By"] || "");
setVehicleId(vehicleData.vehicle["Vehicle_Id"]);
setVechicleVideo(vehicleData.vehicle["Vehicle_video"]);
setVechicleVideo(vehicleData.vehicle["Vehicle_video"]);
setVechicleNotes(vehicleData.vehicle["Car_Notes"]);
setSellerTransmission(vehicleData.vehicle["Transmission_Type"]);
setOwnerShip(vehicleData.vehicle.Ownership_Doc);
setDrivingL(vehicleData.vehicle.Driving_Licence_Doc);
setSelectedFeatures(vehicleData.vehicle.Additional_Car_Feature);
const vin = vehicleData.vehicle.VIN;
if (vehicleData.vehicle["Leased_Financed"] === 1) {
setFinanced("Yes");
}
if (vehicleData.vehicle["Car_Modification"] === 1) {
setCarMdify("Yes");
}
if (vehicleData.vehicle["Any_Vehicle_Issues"] === 1) {
setIssueVichle("Yes");
}
if (vehicleData.vehicle["Stock_Rims"] === 1) {
setStockRim("Yes");
}
if (vehicleData.vehicle["Accident_Claims"] === 1) {
setAccidentClaims("Yes");
}
if (vehicleData.vehicle["Donot_Need_Car"] === 1) {
setDonotNeedCar("Don’t Need The Car Anymore");
}
if (vehicleData.vehicle["Mech_Elect_Issues"] === 1) {
setMechElectIssues(
"Selling Because The Car Has Some Mechanical/ Electrical Issues"
);
}
if (vehicleData.vehicle["Downsize"] === 1) {
setDownSize("Selling To Downsize");
}
if (vehicleData.vehicle["Buy_Another_Car"] === 1) {
setBuyAnotherCar("Selling To Buy Another Car");
}
if (vehicleData.vehicle["Winter_Tires"] === 1) {
setHasWinterTires("Yes");
}
if (vehicleData.vehicle["TradeIn_Interested"] === 1) {
setTradeInInterest("Yes");
}
if (vehicleData.vehicle["Extended_Warranty"] === 1) {
setExtendedWarranty("Yes");
}
if (vehicleData.vehicle["Windshield_Crack"] === 1) {
setCrackOnWindshield("Yes");
}
if (vehicleData.vehicle["Vehicle_Drivable"] === 1) {
setVehicleDrivable("Yes");
}
if (vehicleData.vehicle["Tires_Repld_12_Months"] === 1) {
setTireReplacement("Yes");
}
if (vehicleData.vehicle["Original_Factory_Rims"] === 1) {
setHasOriginalRims("Yes");
}
if (vehicleData.vehicle["Original_Factory_Rims"] === 1) {
setHasOriginalRims("Yes");
}
if (vehicleData.vehicle["Smoke_In_Vehicle"] === 1) {
setSmokeInVehicle("Yes");
}
if (vehicleData.vehicle["2_Sets_Of_Tire"] === 1) {
setSetTire("2 Sets of Tire");
}
if (vehicleData.vehicle["Win_Tint"] === 1) {
setWindowTint("Window Tint");
}
if (vehicleData.vehicle["Aftermark_Exhaust"] === 1) {
setAftermarketExhaust("Aftermarket Exhaust");
}
if (vehicleData.vehicle["Aftermark_Rims"] === 1) {
setAftermarkRims("Aftermarket Rims");
}
if (vehicleData.vehicle["Roof_Rack"] === 1) {
setRoofRack("Roof Rack");
}
if (vehicleData.vehicle["Remote_Start"] === 1) {
setRemoteStarter("Remote Start");
}
if (vehicleData.vehicle["Aftermark_Stereo"] === 1) {
setAftermarkStereo("Aftermarket Stereo");
}
if (vehicleData.vehicle["Aftermark_Spoiler"] === 1) {
setAftermarketSpoiler("Aftermarket Spoiler");
}
if (vehicleData.vehicle["Minor_Damage"] === 1) {
setMinorDamage("Minor Damage");
}
if (vehicleData.vehicle["Fading_Paints"] === 1) {
setFadingPaints("Fading Paints");
}
if (vehicleData.vehicle["Rust"] === 1) {
setRust("Rust");
}
if (vehicleData.vehicle["Hail_Damage"] === 1) {
setHailDamage("Hail Damage");
}
if (vehicleData.vehicle["Ext_Mint_Condition"] === 1) {
setExtMintCondition("Mint Condition");
}
if (vehicleData.vehicle["Dents"] === 1) {
setDents("Dents");
}
if (vehicleData.vehicle["Int_Mint_Condition"] === 1) {
setIntMintCondition("Mint Condition");
}
if (vehicleData.vehicle["Nav_Entmt_Ctrl_Dmg_Sys"] === 1) {
setDamagedSystems("Damaged Systems");
}
if (vehicleData.vehicle["Strong_Smell"] === 1) {
setStrongSmell("Strong Smell");
}
if (vehicleData.vehicle["Seats_Visible_Stain"] === 1) {
setVisibleStain("Visible Stain On The Seats");
}
if (vehicleData.vehicle["Seats_Rips_Tears"] === 1) {
setRipsOrTears("Rips Or Tears On The Seats");
}
try {
  const response = await api.get(`/seller/carfaxebadgingreport/${Vehicle_Id}/${vin}`);
  const data = response.data;

  if (data.Imageurl) {
    setImageUrl(data.Imageurl); // Set the image URL if available
    setMessage(data.Message || ""); // Set the message if available
  } else {
    setMessage("No Badge Found");
  }
} catch (error) {
  console.error("Error fetching image:", error);
  setMessage("Error fetching image.");
}
} catch (error) {
console.error("Error fetching vehicle details:", error);
}
};

useEffect(() => {
   if (!targetTime) return;
   
   const updateCountdown = () => {
     const now = dayjs().tz('Asia/Kolkata');
     const difference = targetTime.diff(now);
     
     if (difference <= 0) {
       setIsTimeUp(true);
       sethours(0);
       setminutes(0);
       setseconds(0);
       return;
     }
     
     const totalHours = Math.floor(difference / (1000 * 60 * 60));
     const minutes = Math.floor((difference / (1000 * 60)) % 60);
     const seconds = Math.floor((difference / 1000) % 60);
     
     setCountdown(`${totalHours}Hours ${minutes}Minutes ${seconds}Seconds`);
     sethours(totalHours);
     setminutes(minutes);
     setseconds(seconds);
   };
   
   const interval = setInterval(updateCountdown, 1000);
   return () => clearInterval(interval);
 }, [targetTime]);
 
 // Fetch the auction timeout when component mounts
 useEffect(() => {
   fetchAuctionTimeout();
 }, []);



const features = [
settire,
windowtint,
aftermarketexhaust,
roofrack,
remotestarter,
aftermarkstereo,
aftermarketspoiler,
];
const filteredFeatures = features.filter((feature) => feature);
const featuresString = filteredFeatures.join(",");
const featurestwo = [
minordamage,
fadingpaints,
rust,
haildamage,
extmintcondition,
dents,
];
const filteredFeaturestwo = featurestwo.filter((feature) => feature);
const featuresStringtwo = filteredFeaturestwo.join(",");
const featuresthree = [
ripsOrTears,
visibleStain,
strongSmell,
damagedSystems,
IntmintCondition,
];
const filteredFeaturesthree = featuresthree.filter((feature) => feature);
const featuresStringthree = filteredFeaturesthree.join(",");
const initializeFlexSlider = () => {
window.$(".flexslider").flexslider({
animation: "slide",
controlNav: "thumbnails",
start: function (slider) {
window.$("body").removeClass("loading");
},
});
};
const handleClick = () => {
// Extract the vehicle ID from the URL
const url = window.location.href;
const urlParts = url.split("/");
const vehicleId = urlParts[urlParts.length - 1]; // Assuming vehicle ID is the last part of the URL
// Store the vehicle ID in session storage
sessionStorage.setItem("Vehicle_Id", vehicleId);
// Log the session value
const sessionValue = sessionStorage.getItem("Vehicle_Id");
};
useEffect(() => {
   fetchAuctionTimeout();
 }, []);
 const fetchAuctionTimeout = async () => {
   try {
     const response = await api.get(`/timeoutdetails/${Vehicle_Id}`);
     console.log(`API Response for Vehicle ${Vehicle_Id}:`, response.data);
     const hoursOnly = Math.floor(response.data.counterminutes / 60);
     const minutesOnly = response.data.counterminutes % 60;
     setTimeLeft({ hours: hoursOnly, minutes: minutesOnly });
   } catch (error) {
     console.error(`Error fetching auction timeout for Vehicle ${Vehicle_Id}:`, error);
   }
 };
 
  const fetchBidHistory = async () => {
   try {
     const response = await api.get(`/bidhistory/${Vehicle_Id}`);
     const data = response.data;
     console.log(data)
     setTopBids(data.TopBids);
   } catch (error) {
     console.error('Error fetching bid history:', error);
   }
 };

 useEffect(() => {
  if (Vehicle_Id) {
    fetchBidHistory();
  }
}, [Vehicle_Id]);
 useEffect(() => {
   if (firmprice) {  // Ensure firmprice is set before fetching
     fetchBuyNowData();
   }
 }, [firmprice]);
 const fetchBuyNowData = async () => {
   try {
     const response = await api.get(`/dealer/showbuynow/${Vehicle_Id}/${firmprice}`);
     const data = response.data;
      setShowNow(data.result)
   } catch (error) {
     console.error('Error fetching bid history:', error);
   }
 };
 const fetchBuyNow = async () => {
   try {
     const response = await api.get(`/dealer/buynow/${Vehicle_Id}/${dealerId}/${firmprice}`);
     const data = response.data;
      console.log("shownow",data)
   } catch (error) {
     console.error('Error fetching bid history:', error);
   }
 };
 const handleBidPriceChange = (e) => {
   let value = e.target.value;
   value = value.replace(/[^\d.]/g, "");
   const parts = value.split(".");
   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   const formattedValue = `$${parts.join(".")}`;
   setNewBidAmount(formattedValue);
 };
 
 const formatWithCommas = (value) => {
   // Convert the value to a float, format it with commas, and prepend the dollar sign
   if (isNaN(value) || value === null || value === undefined) {
   return "$0.00";
   }
   return `$${parseFloat(value).toLocaleString()}`;
   };
 
 const handleAddNewBid = async () => {
   // Ensure the bid amount is not empty
   const dealerId = sessionStorage.getItem("user_id");
   
   if (!newBidAmount || newBidAmount.trim() === "") {
     alert("Bid amount cannot be empty.");
     return;
   }
   
   const numericBidPrice = parseFloat(newBidAmount.replace(/[$,]/g, ''));
   
    // Ensure the bid increment is at least 100
    if (numericBidPrice <= highestbid + 100) {
     alert(`Your bid must be at least $100 higher than the highest bid.`);
     return;
   }

   // Check if the new bid is lower or equal to the highest bid
   if (numericBidPrice < highestbid) {
     alert(`Your bid must be greater than the current highest bid of ${highestbid}`);
     return;
   }
 
   // Check if the bid is lower than the firm price
   if (numericBidPrice < highestbid) {
     alert(`Bid price must be greater than ${formatWithCommas(highestbid)}`);
     return;
   }
   try {
     const response = await api.post("/dealer/dealerbidprice", {
       Bid_Amount: numericBidPrice,
       Dealer_Id: dealerId,
       Vehicle_Id: Vehicle_Id,
     });
     setResponseMessage(response.data.message || "Bid price saved successfully");
     setTimeout(() => setResponseMessage(''), 3000); // Clear message after 3 seconds
     fetchBidHistory(); // Refresh bid history after saving
   } catch (error) {
     console.error("Error saving bid price:", error);
   }
 };
 const fetchAgentData = async () => {
   try {
     const response = await api.get(`/agent/agentdetails/${Vehicle_Id}`);
     console.log(response.data); // Log the response to check the data
     setAgentDetails(response.data[0]); // Set the first agent object
   } catch (error) {
     console.error("Error fetching agent details:", error);
   } finally {
     setIsLoading(false); // Stop loading
   }
 };
 const fetchShowDeliveryMode = async () => {
   try {
     const response = await api.get(`/dealer/showdeliverymode/${Vehicle_Id}/${dealerId}`);
     const data = response.data;
     setDeliveryModeShow(data.result)
   } catch (error) {
     console.error("Error fetching agent details:", error);
   } finally {
     setIsLoading(false); // Stop loading
   }
 };
 const fetchDeliveryModePass = async () => {
   try {
     const response = await api.post(`/dealer/updatedeliverymode/${Vehicle_Id}/${dealerId}`, {
       delivery_mode: deliveryMode, // Pass delivery mode as payload
     });
     console.log("Delivery mode updated:", response.data);
   } catch (error) {
     console.error("Error updating delivery mode:", error);
   } finally {
     setIsLoading(false); // Stop loading
   }
 };
 const fetchAuctionOver = async () => {
  try {
    const response = await api.get(`/auction_over/${Vehicle_Id}`);
    console.log("auction_over:", response.data);
    setAuctionOverData(response.data.message);  // Store data in state to display

    setError(null); // Clear previous errors
  } catch (error) {
    console.error("Error updating delivery mode:", error);
    setError("Failed to fetch auction data.");
  } finally {
    setIsLoading(false); // Stop loading
  }
};
useEffect(() => {
  if (Vehicle_Id) {
    fetchAuctionOver();
    fetchState();
  }
}, [Vehicle_Id]);

const setAlert = async () => {
  try {
    // Making the API call with Vehicle_Id and dealerId
    const response = await api.post("/dealer/setalert", {
      vehicleid: Vehicle_Id,
      dealerid: dealerId,
    });

    console.log("Set Alert Response:", response.data); // Logging for debugging
    alert("Alert set successfully!"); // You can change this to a more user-friendly message or UI feedback
  } catch (error) {
    console.error("Error setting alert:", error);
    alert("Failed to set alert. Please try again."); // Error feedback
  }
};
const fetchState = async () => {
  try {
    const response = await api.get(`/car-location/${location}`);
    console.log("State:", response.data);
    
    if (response.data && response.data.province) {
      setProvince(response.data.province); // Set the province if it's present in the response
    } else {
      console.warn("Province not found in response");
    }

  } catch (error) {
    console.error("State fetch error:", error);
  } finally {
    setIsLoading(false); // Stop loading
  }
};
  // Fetch state data when the location changes and is non-empty
  useEffect(() => {
    if (location) {
      fetchState();
    }
  }, [location]);
  // Add Car to watch list
  const Watchlist = async () => {
    try {
      const response = await api.get(`/dealer/addtowatchlist/${Vehicle_Id}/${dealerId}`);
      const data = response.data;
       console.log("watchlist",data)
    } catch (error) {
      console.error('Error post watchlist:', error);
    }
  };

    // Add Car to watch list get
    const Watchlisfetcht = async () => {
      try {
        const response = await api.get(`/dealer/existinwatchlist/${Vehicle_Id}/${dealerId}`);
        const data = response.data;
        setIsInWatchlist(data.result); 
      } catch (error) {
        console.error('Error post watchlist:', error);
      }
    };
    
    useEffect(() => {
      Watchlisfetcht();  // Call the function here
    }, []);
    useEffect(() => {
      function start() {
        gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
          scope: SCOPES,
        });
      }
      gapi.load('client:auth2', start);
    }, []);
  

    // Initialize Google API client
useEffect(() => {
 gapi.load("client:auth2", () => {
   gapi.client
     .init({
       apiKey: API_KEY,
       clientId: CLIENT_ID,
       discoveryDocs: [
         "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
       ],
       scope: SCOPES,
     })
     .catch((error) => console.error("Error initializing Google API client", error));
 });
}, []);
    const Calender = async () => {
      

    const authInstance = gapi.auth2.getAuthInstance();
    
    // Check if the user is signed in
    if (authInstance.isSignedIn.get()) {
      // If signed in, create the event
      createEvent();
    } else {
      // If not signed in, trigger sign-in
      authInstance.signIn().then(() => {
        createEvent(); // Proceed with event creation after successful sign-in
      }).catch((error) => {
        console.error("Error signing in: ", error);
        alert("Failed to sign in. Please try again.");
      });
    }
  };

  const createEvent = () => {
    // Event data
    const event = {
      summary: `Car Chaser Auction`,
      description: ` ${year} ${make} ${model} ${trim} ${vin}`,
      start: {
        dateTime: saledate, 
        timeZone: "America/Los_Angeles",
      },
      end: {
        dateTime: saledate, // End date and time
        timeZone: "America/Los_Angeles",
      },
    };

    // Insert event into Google Calendar
    gapi.client.calendar.events
      .insert({
        calendarId: "primary",
        resource: event,
      })
      .then((response) => {
        console.log("Event created: ", response);
        alert("Event added to calendar!");
        navigate(`/future-car-details/${Vehicle_Id}`); // Redirect after event is added
      })
      .catch((error) => console.error("Error creating event", error));
    };
 
    const postAutoBid = async () => {
      try {
          // Remove formatting (commas) by converting formatted values back to numbers
          const cleanStartBid = parseFloat(startBid.replace(/,/g, ""));
          const cleanMaxBid = parseFloat(maxBid.replace(/,/g, ""));
          
          const response = await api.post(`/dealer/setautobid`, {
              vehicle_id: Vehicle_Id,
              dealer_id: dealerId,
              start_bid_amount: cleanStartBid,
              maximum_bid_amount: cleanMaxBid,
          });
          setAutoSuccessfullMesage("Bid saved successfully");
          console.log("Bids saved successfully:", response.data);
      } catch (error) {
          console.error("Error saving bids:", error);
      }
  };


  useEffect(() => {
    const GetAutoBid = async () => {
        try {
            const response = await api.get(`/dealer/getautobid/${Vehicle_Id}/${dealerId}`);
            const data = response.data;

            // Assuming `data.result` is an array and we take the first object in that array
            if (data.result && data.result.length > 0) {
                const autoBidData = data.result[0];
                
                // Set the values from `Start_Bid_Amt` and `Max_Bid_Amt`
                setStartBid(autoBidData.Start_Bid_Amt.toString());
                setMaxBid(autoBidData.Max_Bid_Amt.toString());
                
                // Set the formatted values
                setFormattedStartBid(formatNumber(autoBidData.Start_Bid_Amt.toString()));
                setFormattedMaxBid(formatNumber(autoBidData.Max_Bid_Amt.toString()));
            }
        } catch (error) {
            console.error("Error fetching bids:", error);
        }
    };

    GetAutoBid();
}, [Vehicle_Id, dealerId]);

useEffect(() => {
  const GetMaxmiumBid = async () => {
      try {
          const response = await api.get(`/dealer/checkmaxbid/${Vehicle_Id}/${dealerId}/5000`);
          const data = response.data;

    
      } catch (error) {
          console.error("Error fetching bids:", error);
      }
  };

  GetMaxmiumBid();
}, [Vehicle_Id, dealerId]);

const dynamicLink = evaluationreport && evaluationreport !== "" 
  ? `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${evaluationreport}` 
  : null; // or any fallback URL you prefer

 // Logic to show alert if evaluationreport is empty
return (
<section class="car-details">
   <SellerNav />
   <div class="vehicle-detials-page mt-4">
        <div class="container">
        <div class="row">
            <div class="col-lg-3 mb-lg-0 mb-4">
            <div className={vechiclevideo && vechiclevideo !== "null" ? "col-md-12 p-0" : "col-md-12 p-0 text-center"}>
            <div class="car-list-image">
               <div class="flexslider">
                  <ul className="slides">
                     {interiorimage && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`}
                           alt="Interior"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {interiorimagetwo && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`}
                           alt="Interior"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {interiorimagethree && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`}
                           alt="Interior"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {interiorimagefour && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`}
                           alt="Interior"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {exteriorimage && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`}
                           alt="Exterior"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {exteriorimagetwo && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`}
                           alt="Exterior"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {exteriorimagethree && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`}
                           alt="Exterior"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {exteriorimagefour && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`}
                           alt="Exterior"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {dashboardimage && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`}
                           alt="Dashboard"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {dashboardimagetwo && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`}
                           alt="Dashboard"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {dashboardimagethree && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`}
                           alt="Dashboard"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {dashboardimagefour && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`}
                           alt="Dashboard"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {rimsimage && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`}
                           alt="Rims"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {rimsimagetwo && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`}
                           alt="Rims"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {rimsimagethree && (
                     <li
                        data-thumb={`https://topdevit.com/clients/carchaser/public/uploads/${Vehicle_Id}/${rimsimagethree}`}
                        >
                        <img
                           src={`https://topdevit.com/clients/carchaser/public/uploads/${Vehicle_Id}/${rimsimagethree}`}
                           alt="Rims"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagethree}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                     {rimsimagefour && (
                     <li
                        data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`}
                        >
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`}
                           alt="Rims"
                           onClick={() => openModal("image", `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`)}
                           style={{ cursor: 'pointer' }}
                           />
                     </li>
                     )}
                  </ul>
               </div>
            </div> 
         </div>
    <div>
    {vechiclevideo && vechiclevideo !== "null" && (
  <div className="col-md-12 p-0">
    <div id="vechiclevideo">
      <h2>Vehicle Video</h2>
      <div 
        onClick={() => openModal("video", `https://backend.carchaser.ca/videos/${Vehicle_Id}/${vechiclevideo}`)} 
        style={{ cursor: 'pointer' }}
      >
        <video
          width="250"
          height="315"
          controls
          preload="metadata"
        >
          <source
            src={`https://backend.carchaser.ca/videos/${Vehicle_Id}/${vechiclevideo}`}
            type="video/mp4"
          />
          <source
            src={`https://backend.carchaser.ca/videos/${Vehicle_Id}/${vechiclevideo}`}
            type="video/quicktime"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
)}
      {/* Modal Implementation */}
      {isModalOpen && (
  <div className="modal-overlay" onClick={closeModal}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <span className="close-button" onClick={closeModal}>&times;</span>

      {/* Add Previous and Next Arrows */}
      <button className="prev-arrow" onClick={() => navigateImage("prev")}>
        &#10094;
      </button>

      {modalContent.type === "video" ? (
        <video width="100%" height="100%" controls preload="metadata">
          <source src={modalContent.src} type="video/mp4" />
          <source src={modalContent.src} type="video/quicktime" />
          Your browser does not support the video tag.
        </video>
      ) : (
        modalContent.src && (
          <img src={modalContent.src} alt="Vehicle" style={{ width: "100%", height: "auto" }} />
        )
  
      )}

      {/* Add Next Arrow */}
      <button className="next-arrow" onClick={() => navigateImage("next")}>
        &#10095;
      </button>
    </div>
  </div>
)}

{/* CSS for modal styling */}
<style jsx>{`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-content {
    position: relative;
    width: 90%;
    max-width: 1000px;
    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }
  .modal-content img {
    width: auto !important;
    height: 100% !important;
    margin: auto !important;
  }

  /* Add styles for navigation arrows */
  .prev-arrow, .next-arrow {
    position: absolute;
    top: 50%;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    font-size: 30px;
    cursor: pointer;
    transform: translateY(-50%);
  }
  .prev-arrow {
    left: 10px;
  }
  .next-arrow {
    right: 10px;
  }
`}</style>
    </div>
    </div>
            <div class="col-lg-6 mb-lg-0 mb-4">
                <div class="vehicle-details-panel">
                    <h3 class="headline p-2">Vehicle Details</h3>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="details-info-one">
                                <ul class="list">
                                    <li>
                                        <h4 class="info-headline">Location:</h4>
                                        <span class="info">{location}</span>
                                    </li>
                                    <li>
                                        <h4 class="info-headline">Vin Number:</h4>
                                        <span class="info">{vin}</span>
                                    </li>
                                    <li>
                                        <h4 class="info-headline">Year:</h4>
                                        <span class="info">{year}</span>
                                    </li>
                                    <li>
                                        <h4 class="info-headline">Make:</h4>
                                        <span class="info">{make}</span>
                                    </li>
                                    <li>
                                        <h4 class="info-headline">Model:</h4>
                                        <span class="info">{model}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="details-info-one">
                                <ul class="list">
                                    <li>
                                        <h4 class="info-headline">Trim :</h4>
                                        <span class="info">{trim}</span>
                                    </li>
                                    <li>
                                        <h4 class="info-headline">Transmission :</h4>
                                        <span class="info">{sellertransmission}</span>
                                    </li>
                                    <li>
                                        <h4 class="info-headline">Mileage :</h4>
                                        <span class="info">{mileage.toLocaleString()}</span>
                                    </li>
                                    <li>
                                        <h4 class="info-headline">Color :</h4>
                                        <span class="info">{color}</span>
                                    </li>
                                    <li>
                                        <h4 class="info-headline">Keys :</h4>
                                        <span class="info">{keys}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>        
                    </div>

                    <div class="row m-0">
                        <h4 class="info-headline">External Damage to the vehicle.</h4>
                        <span class="info-value w-100 mb-3">{featuresStringtwo}</span>

                        <h4 class="info-headline">Any interior damage?</h4>
                        <span class="info w-100 mb-4">{featuresStringthree}</span>
                        <h4 class="info-headline">Additional car features</h4>
                        <span class="info w-100 mb-4">{selectedFeatures}</span>
                        <h4 class="info-headline">Our top priority is to sell your car.</h4>
                        <div class="details-info-two">
                            <ul class="list">
                                <li>
                                    <p>Do you smoke in this vehicle?</p>
                                    <span class="value">{smokeInVehicle}</span>
                                </li>
                                <li>
                                    <p>Do you have original factory rims?</p>
                                    <span class="value">{hasOriginalRims}</span>
                                </li>

                                <li>
                                    <p>Have you replaced your tires in last 12 months?</p>
                                    <span class="value">{tireReplacement}</span>
                                </li>

                                <li>
                                    <p>Is your car driveable?</p>
                                    <span class="value">{vehicleDrivable}</span>
                                    <span class="value">{vehicledrivabledesc}</span>
                                </li>

                                <li>
                                    <p>Any crack on the windshield?</p>
                                    <span class="value">{crackOnWindshield}</span>
                                </li>

                                <li>
                                    <p>Do you have any extended warranty?</p>
                                    <span class="value">{extendedWarranty}</span>
                                </li>

                                <li>
                                    <p>Are you interested in a trade In?</p>
                                    <span class="value">{tradeInInterest}</span>
                                </li>

                                <li>
                                    <p>Why are you selling?</p>
                                    <span class="value">{DonotNeedCar}</span>
                                    <span class="value">{MechElectIssues}</span>
                                    <span class="value">{DownSize}</span>
                                    <span class="value">{BuyAnotherCar}</span>
                                </li>

                                <li>
                                    <p>Describe the condition of the car</p>
                                    <span class="value">{carCondition}</span>
                                </li>

                                <li>
                                    <p>Any Accident Claims? How Much?</p>
                                    <span class="value">{accidentclaims}</span>
                                    <span class="value">{howmuchclaims}</span>  
                                </li>

                                <li>
                                    <p>What Rims Are On The Car?</p>
                                    <span class="value">{carrims}</span>
                                </li>

                                <li>
                                    <p>Do You Have Stock Rims?</p>
                                    <span class="value">{stockrim}</span>
                                </li>
                                <li>
                                    <p>Any Issues With The Vehicle?</p>
                                    <span class="value">{issuevichle}</span>
                                    <span class="value">{issuevichledecs}</span>
                                    
                                </li>
                                <li>
                                    <p>Are There Any Modifications On The Car?</p>
                                    <span class="value">{carmodify}</span>
                                    <span class="value">{carmodifydecs}</span>
                                </li>
                                <li>
                                    <p>Is The Car Leased Or Financed?</p>
                                    <span class="value">{financed}</span>
                                    <span class="value">{financeddesc}</span>
                                </li>
                                <li>
                                    <p>Car Notes</p>
                                    <span class="value">{vechiclenotes}</span>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="report-section">
                        <h4 class="info-headline mb-3">Reports and Services</h4>
                        <div class="car-report text-center">
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="Badge"
                            style={{ width: '200px', height: 'auto', cursor: 'pointer' }}
                            onClick={() => {
                              // Log values to check what's happening
                              console.log("evaluationreport: ", evaluationreport);
                              console.log("dynamicLink: ", dynamicLink);

                              // Check if evaluation report is available
                              if (!evaluationreport || evaluationreport === "") {
                                alert("Your CarFax report is not available.");
                              } else if (!dynamicLink || dynamicLink.includes("null")) {
                                // Check if the dynamic link contains "null"
                                alert("Your CarFax report is not available.");
                              } else {
                                window.open(dynamicLink, "_blank");
                              }
                            }}
                          />
                        ) : (
                          <p>{message}</p>
                        )}
                        </div>
                    </div>

                    <div class="alert-panel mt-3">
                        <h4 class="info-headline mb-0"><i class="fa-regular fa-bell "></i>  Get Alerts on similar vehicles </h4>
                        <button type="button" class="btn btn-outline-primary" onClick={setAlert}>Set Alert</button>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
        <div class="form-group row">
            <button class="btn btn-primary mr-2" onClick={Calender}><i class="fa-solid fa-calendar-plus"></i> Add to Calendar</button>
            {isInWatchlist ? (
           <h4 class="info-headline mb-3">This Vehicle is in your watch list</h4>
      ) : (
        <button className="btn btn-primary mt-2" onClick={Watchlist}>
          <i className="fa-solid fa-cart-shopping"></i> Add to Watch List
        </button>
      )}
           
        </div>
     
      <h4 class="info-headline mb-3 mt-4">Auto Bid</h4>
      <div class="form-group row">
          <label for="" class="col-sm-12 col-form-label">Starting Bid <i class="fa-solid fa-circle-info"></i></label>
          <div class="col-sm-12">
          <div class="position-relative icon-field">
                <i class="fa-solid fa-dollar-sign icon"></i>
                <input
                    class="form-control"
                    type="text"
                    id="startBid"
                    value={formattedStartBid}
                    onChange={(e) => handleBidChange(e, "start")}
                />
            </div>
            {startBidMessage && (
          <div className="alert alert-warning mt-2">
            <strong>Start Bid Message:</strong> {startBidMessage}
          </div>
        )}
          </div>
        </div>
        <div class="form-group row">
          <label for="" class="col-sm-12 col-form-label">Maximum Bid</label>
          <div class="col-sm-12">
            <div class="position-relative icon-field">
                <i class="fa-solid fa-dollar-sign icon"></i>
                <input
                   class="form-control"
                    type="text"
                    id="maxBid"
                    value={formattedMaxBid}
                    onChange={(e) => handleBidChange(e, "max")}
                />
            </div>
            {maxBidMessage && (
                  <div className="alert alert-warning mt-2">
                    <strong>Max Bid Message:</strong> {maxBidMessage}
                  </div>
                )}
          </div>
        </div>
              <button
              onClick={postAutoBid}
              className="btn btn-primary mr-2"
              disabled={isButtonDisabled} // Button is disabled if both messages are empty
            >
              Save Bids
            </button>
         <div class="msg">
            <p class="text-success">{AutoSuccessfullMesage} </p> 
         </div>
        <div class="bg-light p-3 flex-wrap mt-3">
                    <h4 class="info-headline mb-2 text-primary">Sale Information</h4>
                    <ul class="report-list">
                        <li>
                            <p class="headline">Sales Rep: </p>
                            <p>{agentDetails ? `${agentDetails.Agent_Fname} ${agentDetails.Agent_Lname}` : "N/A"}</p>
                        </li>
                        <li>
                            <p class="headline">Sale Address: </p>
                            <p>{location},{province}</p>
                        </li>
                        <li>
                            <p class="headline">Sale Date: </p>
                            <p>{live}</p>
                        </li>
                    </ul>
                </div>
     
            <h4 class="info-headline mb-0"><i class="fa-regular fa-bell "></i> Featured Vehicle </h4>
                <div class="ad-section mt-3">
                    <img src="../images/ad-image.png" alt="ad-image"></img>
                </div>
            </div>
        </div>
        </div>
    </div>

 
</section>
);
};